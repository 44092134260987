
.dropdown-divider {
    border-top: solid 0.5px #b9b9b9 !important;
}

.page-content {
    margin-top: 0px !important;
    padding-top: 0px !important;
}

.plat_block {
    height: 327px !important;
}

/* .owl-carousel .item { */
/* .owl-item {
    width: 400px !important;
}

.everyday_item {
    width: 400px !important;
} */

.filter-option {
    padding-right: 0px !important;
}

.selectpicker__indicator-separator {
    display: none;
}

.selectpicker__input-container {
    margin: 0px !important;
}

.selectpicker__value-container {
    padding: 0px !important;
}

.selectpicker__placeholder {
    color: #212529 !important;
}

.selectpicker__control:hover {
    /* border-color: transparent !important; */
    box-shadow: none !important;
}

.removeborder .selectpicker__control {
    border-bottom: none !important;
}

.selectpicker__control {
    margin: 0;
    padding: 0px;
    font-size: 18px;
    font-weight: 600;
    border: none;
    border-style: none !important;
    border-bottom: 2px solid #EDEDED !important;
    border-radius: 0 !important;
    outline: none !important;
    box-shadow: none !important;
}

.input {
    margin: 0;
    padding: 0 0 10px;
    font-size: 18px;
    font-weight: 600;
    border: none;
    border-bottom: 2px solid #EDEDED;
    border-radius: 0;
}

.form-control.is-invalid, .was-validated .form-control:invalid {
    border-color: var(--bs-form-invalid-border-color) !important;
    padding-right: calc(1.5em + 0.75rem) !important;
    background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") !important;
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:valid {
    border-color: var(--bs-form-valid-border-color);
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}


.mt-10 {
    margin-top: 10px;
}
.mt-20 {
    margin-top: 20px;
}
.mt-30 {
    margin-top: 30px;
}
.mt-40 {
    margin-top: 40px;
}
.mt-50 {
    margin-top: 50px;
}

.mb-10 {
    margin-bottom: 10px;
}
.mb-20 {
    margin-bottom: 20px;
}
.mb-30 {
    margin-bottom: 30px;
}
.mb-40 {
    margin-bottom: 40px;
}
.mb-50 {
    margin-bottom: 50px;
}

/* .course-block-container {
    display: flex;
    align-items: stretch;
    /* gap: 10px;  *
}

/* .course_block-box { */
    /* height: 610px !important; *
    flex: 1;
    /* padding: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: #fff; *
    min-height: 100px; /* Optional: Prevents blocks from collapsing *
} */

/* .course_block-fixed-h {
    height: 610px !important;
    flex: 1;
    /* padding: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    /* background-color: #fff; *
    min-height: 100px; 
} */

.border-rounded {
    border-radius: 50% !important;
    border-width: 1px;
    border-style: solid;
    border-color: lightgray;
    padding: 5px;
}

.skill-tag {
    padding: 7px 10px;
    background: whitesmoke;
    border-radius: 5px;
    font-size: medium;
    margin-right: 7px;
}

.category-title {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 4.5em; /* This assumes each line is 1.5em tall, adjust as needed */
    line-height: 1.5em; /* Adjust based on your font size */
    min-height: 66px;
}

.clamp-text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal; /* Make sure text wraps */
}

.clamp-text-2 {
    display: -webkit-box !important;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal; /* Make sure text wraps */
}

.clamp-text-1 {
    display: -webkit-box !important;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal; /* Make sure text wraps */
}

.custom-job-description {
    margin-bottom: 50px;
}

.custom-job-description li {
    list-style : disc !important;
}

.PartnerSlider .owl-stage-outer .owl-stage .owl-item {
    /* width: 288px !important; */
}

.job-block-custom {
    padding: 25px 35px 25px 35px !important;
}

.job-block-custom .job_item .icon {
    max-width: 100px !important;
    min-width: 100px !important;
    background: none !important;
}

.category_sec .category_box .h3-custom {
    margin: 0 0 10px 0;
    padding: 0;
    font-size: 20px !important;
    font-weight: 700;
    color: var(--lblack);
    min-height: 52px;
    transition: all .4s ease-in-out;
}


.react-multiple-carousel__arrow--right { 
    right: calc(0.4% + 1px) !important;
    top: 0 !important;
}

/* .carousel__arrow--left { */
.react-multiple-carousel__arrow--left {
    /* left: calc(0.4% + 1px) !important; */
    right: calc(4.4% + 1px) !important;
    left: calc(93%) !important;
    top: 0 !important;
}

.react-multi-carousel-list  {
    /* padding: 50px 20px 0px 20px !important; */
    padding: 60px 20px 0px 20px !important;
}

.panel_block .more_link {
    font-size: 22px !important;
}
.panel_block:hover .more_link {
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
}

.panel_block .more_link {
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
}

.selectpicker__menu {
    z-index: 10000 !important;
}

.custom-carousel-title {
    position: absolute !important;
    margin-bottom: 0px !important;
    margin-top: 5px !important;
}

.custom-image-container {
    height: 215px;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom-nav-link {
    cursor: pointer;
}

.custom-dropdown-toggle {
    cursor: pointer;
}

.custom-signup-input-group {
    text-align: left;
    margin-bottom: 20px !important;
}

.custom-terms_inf {
    margin: 10px auto !important;
    margin-bottom: 20px !important;
}

.custom-loginwith_btn {
    justify-content: center;
    padding: 16px 0px !important;
}

.underline-highlight {
    text-decoration: underline;
    color: #0170ab;
}
.footer-partner-icon {
    cursor: pointer;
}

.partner_logo {
    cursor: pointer;
}

.ic-btn {
    padding: 10px 15px 10px 15PX !important;
    margin-left: 10px !important;
    display: inline-block !important;
}

.login-error {
    min-height: 20px !important;
    margin-top: 20px;
    margin-bottom: 0px;
}

.build-profile-btn {
    margin: 0 auto;
    display: flex;
}

.force-w100 {
    width: 100% !important;
    max-width: 100% !important;
}

.yellow-underline {
    position: relative;
    display: inline-block;
    padding-bottom: 5px;
}

.yellow-underline::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px; /* Adjust the thickness of the underline */
    bottom: 0;
    left: 0;
    background-color: #fcba33; /* Adjust the color as needed */
    z-index: -1; /* Ensure the underline appears behind the text */
}

.inner_banner .cap_info h1:before {
    content: "";
    width: 30%;
    height: 10%;
    background: url(../public/images/hero_curve.png) center center no-repeat;
    background-size: 100% 100%;
    position: absolute;
    bottom: 15%;
    left: 5%;
}

/* .inner_banner .cap_info h1:before {
    content: "";
    width: 324px;
    height: 18px;
    background: url(../public/images/hero_curve.png) center center no-repeat;
    background-size: 100% 100%;
    position: absolute;
    bottom: 40px;
    left: 74px;
} */

.job-img {
    max-height: 423px;
    object-fit: cover;
}

.custom-course-img {
    display: flex !important;
    justify-content: left;
    height: 70px !important;
    align-items: center;
}

.campus-img {
    height: 170px !important;
    /* object-fit: contain; */
}

.react-flow__handle {
    opacity: 0 !important;
}

.job-block-custom {
    position: relative !important;
}

.like-btn {
    border: none !important;
    background: none !important;
    position: absolute;
    right: 15px;
    top: 10px;
}

.fa-heart-o.heart-icon {
    font-size: 24px;
    color: red !important;
}

.fa-heart.heart-icon {
    font-size: 24px;
    color: red !important;
}

.badge {
    color: #999999;
    /* color: #0070aa; */
}

.form-control:disabled {
    background-color: transparent;
    color: #999999;
}

@media only screen and (max-width: 768px) {
    .inner_banner .cap_info h1:before {
        content: "";
        width: 60%;
        height: 10%;
        background: url(../public/images/hero_curve.png) center center no-repeat;
        background-size: 100% 100%;
        position: absolute;
        bottom: 10%;
        left: 5%;
    }
}


@media (min-width: 1200px) {
    body[data-layout="horizontal"] .container-fluid, body[data-layout="horizontal"] .container-sm, body[data-layout="horizontal"] .container-md, body[data-layout="horizontal"] .container-lg, body[data-layout="horizontal"] .container-xl, body[data-layout="horizontal"] .container-xxl {
        max-width: 100% !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}

body[data-layout="horizontal"] .page-content {
    /* margin-top: 70px; */
    /* padding: calc(180px + 1.5rem) calc(1.5rem / 2) 60px calc(1.5rem / 2); */
    padding: 0px !important;
}